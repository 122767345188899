import React from "react";
import "../App.css";

import img1 from "../assets/pdf/msme/1-1.png";
import img2 from "../assets/pdf/msme/2-1.png";
import img3 from "../assets/pdf/msme/3-1.png";
import img4 from "../assets/pdf/msme/4-1.png";
import img5 from "../assets/pdf/msme/5-1.png";

// import img5 from "../assets/pdf/msme/5-1.pdf";

import img6 from "../assets/pdf/msme/6-1.png";
import img7 from "../assets/pdf/msme/7-1.png";

import img8 from "../assets/pdf/msme/8-1.png";
import img9 from "../assets/pdf/msme/9-1.png";
import img10 from "../assets/pdf/msme/10-1.png";
import img11 from "../assets/pdf/msme/11-1.png";

import img12 from "../assets/pdf/msme/12-1.png";
import img13 from "../assets/pdf/msme/13-1.png";
import img14 from "../assets/pdf/msme/14-1.png";
import img15 from "../assets/pdf/msme/15-1.png";

import img16 from "../assets/pdf/msme/16-1.png";

// import img161 from "../assets/pdf/msme/16-1-1.png";

import img17 from "../assets/pdf/msme/17-1.png";
import img18 from "../assets/pdf/msme/18-1.png";
import img19 from "../assets/pdf/msme/19-1.png";
import img20 from "../assets/pdf/msme/20-1.png";
import img21 from "../assets/pdf/msme/21-1.png";
// import img22 from "../assets/pdf/msme/22-1.png";
import img23 from "../assets/pdf/msme/23-1.png";
import img24 from "../assets/pdf/msme/24-1.png";
import img25 from "../assets/pdf/msme/25-1.png";
import img26 from "../assets/pdf/msme/26-1.png";
import img27 from "../assets/pdf/msme/27-1.png";
import img28 from "../assets/pdf/msme/28-1.png";
import img29 from "../assets/pdf/msme/29-1.png";

import Header from "../Common/Header";

export default function Msme() {
  return (
    <div>
      <Header page={"work"} />

      <div style={{ paddingTop: "3rem" }}>
        <img
          alt="logo"
          src={img1}
          style={{ width: "100%", display: "block" }}
        />
        <img
          alt="logo"
          src={img2}
          style={{ width: "100%", display: "block" }}
        />

        <img
          alt="logo"
          src={img3}
          style={{ width: "100%", display: "block" }}
        />
        <img
          alt="logo"
          src={img4}
          style={{ width: "100%", display: "block" }}
        />
        <img
          alt="logo"
          src={img5}
          style={{ width: "100%", display: "block" }}
        />
        <img
          alt="logo"
          src={img6}
          style={{ width: "100%", display: "block" }}
        />

        <img
          alt="logo"
          src={img7}
          style={{ width: "100%", display: "block" }}
        />
        <img
          alt="logo"
          src={img8}
          style={{ width: "100%", display: "block" }}
        />
        <img
          alt="logo"
          src={img9}
          style={{ width: "100%", display: "block" }}
        />
        <img
          alt="logo"
          src={img10}
          style={{ width: "100%", display: "block" }}
        />
        <img
          alt="logo"
          src={img11}
          style={{ width: "100%", display: "block" }}
        />
        <img
          alt="logo"
          src={img12}
          style={{ width: "100%", display: "block" }}
        />
        <img
          alt="logo"
          src={img13}
          style={{ width: "100%", display: "block" }}
        />
        <img
          alt="logo"
          src={img14}
          style={{ width: "100%", display: "block" }}
        />
        <img
          alt="logo"
          src={img15}
          style={{ width: "100%", display: "block" }}
        />
           <img
          alt="logo"
          src={img16}
          style={{ width: "100%", display: "block" }}
        />
                     {/* <img
          alt="logo"
          src={img161}
          style={{ width: "100%", display: "block" }}
        /> */}
           <img
          alt="logo"
          src={img17}
          style={{ width: "100%", display: "block" }}
        />
           <img
          alt="logo"
          src={img18}
          style={{ width: "100%", display: "block" }}
        />
           <img
          alt="logo"
          src={img19}
          style={{ width: "100%", display: "block" }}
        />
           <img
          alt="logo"
          src={img20}
          style={{ width: "100%", display: "block" }}
        />
             <img
          alt="logo"
          src={img21}
          style={{ width: "100%", display: "block" }}
        />
               {/* <img
          alt="logo"
          src={img22}
          style={{ width: "100%", display: "block" }}
        /> */}
               <img
          alt="logo"
          src={img23}
          style={{ width: "100%", display: "block" }}
        />
               <img
          alt="logo"
          src={img24}
          style={{ width: "100%", display: "block" }}
        />
               <img
          alt="logo"
          src={img25}
          style={{ width: "100%", display: "block" }}
        />
               <img
          alt="logo"
          src={img26}
          style={{ width: "100%", display: "block" }}
        />
               <img
          alt="logo"
          src={img27}
          style={{ width: "100%", display: "block" }}
        />
                  <img
          alt="logo"
          src={img28}
          style={{ width: "100%", display: "block" }}
        />
     
     <img
          alt="logo"
          src={img29}
          style={{ width: "100%", display: "block" }}
        />
      </div>
    </div>
  )
}
