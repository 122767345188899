import React from "react";
import "../App.css";

import img1 from "../assets/pdf/puns/1-1.png";
import img2 from "../assets/pdf/puns/2-1.png";
import img3 from "../assets/pdf/puns/3-1.png";
import img4 from "../assets/pdf/puns/4-1.png";
import img5 from "../assets/pdf/puns/5-1.png";

import img6 from "../assets/pdf/puns/6-1.png";



import Header from "../Common/Header";

export default function Puns() {
  return (
    <div>
    <Header page={"work"} />

    <div style={{ paddingTop: "3rem" }}>
      <img
        alt="logo"
        src={img1}
        style={{ width: "100%", display: "block" }}
      />
      <img
        alt="logo"
        src={img2}
        style={{ width: "100%", display: "block" }}
      />

      <img
        alt="logo"
        src={img3}
        style={{ width: "100%", display: "block" }}
      />
      <img
        alt="logo"
        src={img4}
        style={{ width: "100%", display: "block" }}
      />
      <img
        alt="logo"
        src={img5}
        style={{ width: "100%", display: "block" }}
      />
      <img
        alt="logo"
        src={img6}
        style={{ width: "100%", display: "block" }}
      />

    
    
  
     
    </div>
  </div>
  )
}
