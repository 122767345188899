import React, { useState } from "react";
import "../App.css";
import { Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Palate from "../assets/palate.png";

import msme from "../assets/msmenew.png";
import innovation from "../assets/innovation.png";

import porject1 from "../assets/Dana.png";
import project2 from "../assets/MSME.png";

import dd from "../assets/dd.png";

import zine from "../assets/zine.png";
import puns from "../assets/puns.png";

import rush from "../assets/rush.png";

import mood from "../assets/mood.png";
import ddLoader from "../assets/ddLoader.png";
import graphic from "../assets/Graphic.png";

import jenga from "../assets/jenga.png";

import kitchen from "../assets/kitchen.png";

import bookCover from "../assets/bookCover.png";

import Linocut from "../assets/Linocut.png";

import print from "../assets/print.png";

import next from "../assets/right.png";
import circle from "../assets/circle.png";
import Header from "../Common/Header";

export default function Work(props) {
  const navigate = useNavigate();

  let SelectDesign=localStorage?.getItem("SelectDesign")

  const [value, setValue] = useState(SelectDesign ? SelectDesign :"1");

  const [selecteVlue, setSelecteVlue] = useState("UI/UX Case Studies");

  const handleChange = (event, newValue) => {
    setValue(newValue);
    localStorage.setItem("SelectDesign",newValue)
  };
  // console.log(selecteVlue);

  const Tabs = [
    "UI/UX Case Studies",
    "Visual Design",
    "Print Making",
    "Photography",
  ];

  return (
    <>
      <div style={{ height: "100%" }}>
        <Header page={"work"} />

        <div class="main3" id="section2">
          <Grid container style={{ paddingTop: "5rem" }}>
            <div class="SecondSection">
              <Box
                sx={{
                  width: "100%",
                  typography: "body1",
                  justifyContent: "center",
                }}
              >
                <TabContext value={value}>
                  <Box
                    sx={{ borderColor: "divider", justifyContent: "center" }}
                  >
                    <TabList
                      onChange={handleChange}
                      style={{ justifyContent: "center" }}
                    >
                      <Tab label="UI/UX Case Studies" value="1" />
                      <Tab label="Visual Design" value="2" />
                      <Tab label="Print Making" value="3" />
                      <Tab label="Photography" value="4" />
                    </TabList>
                  </Box>

                  <TabPanel value="1">
                    <Grid style={{ marginBottom: "5rem" }}>
                      <Grid
                        container
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        style={{
                          marginTop: "5rem",
                        }}
                      >
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={6}
                          lg={6}
                          style={{
                            // minWidth: "25rem",
                            // minHight: "20rem",
                            // margin: "1rem",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <div
                            class="zoom"
                            onClick={() => {
                              navigate("/graduation");
                            }}
                          >
                            <Typography
                              variant="h5"
                              style={{
                                // color: "white",
                                margin: "1rem 0rem",
                                fontWeight: 500,
                                textAlign: "start",
                              }}
                            >
                              Navigating Innovations : Creating Int...
                            </Typography>
                            <p
                              variant="h7"
                              style={{
                                // color: "white",
                                margin: "1rem 0rem",
                                textAlign: "left",
                                marginTop: "0rem",
                                fontWeight: 300,
                              }}
                            >
                              Graduation Project | Cyntra Labs Pvt Ltd
                              <br />
                              Aug 23 - Jan 24
                            </p>
                            <div
                              style={{ position: "relative", display: "flex" }}
                              class="zoom"
                            >
                              <img
                                style={{
                                  borderRadius: "20px",
                                  
                                  // maxWidth: "400px",
                                  // maxHeight: "400px",
                                  // minHeight: "400px",
                                  // minWidth: "400px",
                                }}
                                src={innovation}
                                alt="img"
                              />
                              <img
                                src={circle}
                                alt="img"
                                style={{
                                  width: "8%",
                                  position: "absolute",
                                  bottom: "15px",
                                  right: "15px",
                                }}
                              />
                              <img
                                src={next}
                                alt="img"
                                style={{
                                  width: "3%",
                                  position: "absolute",
                                  bottom: "29px",
                                  right: "28px",
                                }}
                              />
                            </div>
                          </div>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={6}
                          lg={6}
                          style={{
                            // minWidth: "25rem",
                            // minHight: "20rem",
                            // margin: "1rem",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <div
                            class="zoom"
                            onClick={() => {
                              navigate("/danPatra");
                            }}
                          >
                            <Typography
                              variant="h5"
                              style={{
                                // color: "white",
                                margin: "1rem 0rem",
                                fontWeight: 500,
                                textAlign: "start",
                              }}
                            >
                              DaanPatra
                            </Typography>
                            <p
                              variant="h7"
                              style={{
                                // color: "white",
                                margin: "1rem 0rem",
                                textAlign: "left",
                                marginTop: "0rem",
                                fontWeight: 300,
                              }}
                            >
                              Context and Information Systems
                              <br />
                              UX Design | UI Design | 8 Weeks
                            </p>
                            <div
                              style={{ position: "relative", display: "flex" }}
                              class="zoom"
                            >
                              <img
                                style={{
                                  borderRadius: "20px",
                                  
                                  // maxWidth: "400px",
                                  // maxHeight: "400px",
                                  // minHeight: "400px",
                                  // minWidth: "400px",
                                }}
                                src={porject1}
                                alt="img"
                              />
                              <img
                                src={circle}
                                alt="img"
                                style={{
                                  width: "8%",
                                  position: "absolute",
                                  bottom: "15px",
                                  right: "15px",
                                }}
                              />
                              <img
                                src={next}
                                alt="img"
                                style={{
                                  width: "3%",
                                  position: "absolute",
                                  bottom: "29px",
                                  right: "28px",
                                }}
                              />
                            </div>
                          </div>
                        </Grid>
                      </Grid>

                      <Grid
                        container
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        style={{
                          marginTop: "5rem",
                        }}
                      >
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={6}
                          lg={6}
                          style={{
                            // minWidth: "25rem",
                            // minHight: "20rem",
                            // margin: "1rem",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <div
                            class="zoom"
                            onClick={() => {
                              navigate("/msme");
                              setValue("2");
                            }}
                          >
                            <Typography
                              variant="h5"
                              style={{
                                // color: "white",
                                margin: "1rem 0rem",
                                fontWeight: 500,
                                textAlign: "start",
                              }}
                            >
                              MSME Sathi
                            </Typography>
                            <p
                              variant="h6"
                              style={{
                                // color: "white",
                                margin: "1rem 0rem",
                                textAlign: "left",
                                marginTop: "0rem",
                                fontWeight: 300,
                              }}
                            >
                              Strategies for visual communication
                              <br />
                              Strategy Design | UX Design | 12 Weeks
                            </p>
                            <div
                              style={{ position: "relative", display: "flex" }}
                            >
                              <img
                                style={{
                                  borderRadius: "20px",
                                  
                                  // maxWidth: "400px",
                                  // maxHeight: "400px",
                                  // minHeight: "330px",
                                  // minWidth: "330px",
                                }}
                                src={msme}
                                alt="img"
                              />
                              <img
                                src={circle}
                                alt="img"
                                style={{
                                  width: "8%",
                                  position: "absolute",
                                  bottom: "15px",
                                  right: "15px",
                                }}
                              />
                              <img
                                src={next}
                                alt="img"
                                style={{
                                  width: "3%",
                                  position: "absolute",
                                  bottom: "29px",
                                  right: "28px",
                                }}
                              />
                            </div>
                          </div>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={6}
                          lg={6}
                          style={{
                            // minWidth: "25rem",
                            // minHight: "20rem",
                            // margin: "1rem",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <div
                            class="zoom"
                            onClick={() => {
                              navigate("/jenga");
                            }}
                          >
                            <Typography
                              variant="h5"
                              style={{
                                // color: "white",
                                margin: "1rem 0rem", 
                                fontWeight: 500,
                                textAlign: "start",
                              }}
                            >
                              Digital Game Design : Jenga
                            </Typography>
                            <p
                              variant="h6"
                              style={{
                                // color: "white",
                                margin: "1rem 0rem", 
                                textAlign: "left",
                                marginTop: "0rem",
                                fontWeight: 300,
                              }}
                            >
                              Design Challenge
                              <br />
                              Game Design | UI/UX Design | 2 Days
                            </p>
                            <div
                              style={{ position: "relative", display: "flex" }}
                            >
                              <img
                                style={{
                                  borderRadius: "20px",
                                  
                                  // maxWidth: "400px",
                                  // maxHeight: "400px",
                                  // minHeight: "400px",
                                  // minWidth: "400px",
                                }}
                                src={jenga}
                                alt="img"
                              />
                              <img
                                src={circle}
                                alt="img"
                                style={{
                                  width: "8%",
                                  position: "absolute",
                                  bottom: "15px",
                                  right: "15px",
                                }}
                              />
                              <img
                                src={next}
                                alt="img"
                                style={{
                                  width: "3%",
                                  position: "absolute",
                                  bottom: "29px",
                                  right: "28px",
                                }}
                              />
                            </div>
                          </div>
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        style={{
                          marginTop: "5rem",
                        }}
                      >
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={6}
                          lg={6}
                          style={{
                            // minWidth: "25rem",
                            // minHight: "20rem",
                            // margin: "1rem",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <div
                            class="zoom"
                            onClick={() => {
                              navigate("/kitchen");
                            }}
                          >
                            <Typography
                              variant="h5"
                              style={{
                                // color: "white",
                                margin: "1rem 0rem",
                                fontWeight: 500,
                                textAlign: "start",
                              }}
                            >
                              System Study : Kitchen
                            </Typography>
                            <p
                              variant="h6"
                              style={{
                                // color: "white",
                                margin: "1rem 0rem",
                                textAlign: "left",
                                marginTop: "0rem",
                                fontWeight: 300,
                              }}
                            >
                              Research methodology
                              <br />
                              System Design | UI/UX Design | 2 Weeks
                            </p>
                            <div
                              style={{ position: "relative", display: "flex" }}
                            >
                              <img
                                style={{
                                  borderRadius: "20px",
                                  
                                  // maxWidth: "400px",
                                  // maxHeight: "400px",
                                  // minHeight: "330px",
                                  // minWidth: "330px",
                                }}
                                src={kitchen}
                                alt="img"
                              />
                              <img
                                src={circle}
                                alt="img"
                                style={{
                                  width: "8%",
                                  position: "absolute",
                                  bottom: "15px",
                                  right: "15px",
                                }}
                              />
                              <img
                                src={next}
                                alt="img"
                                style={{
                                  width: "3%",
                                  position: "absolute",
                                  bottom: "29px",
                                  right: "28px",
                                }}
                              />
                            </div>
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                  </TabPanel>
                  <TabPanel value="2">
                    <Grid style={{ marginBottom: "5rem" }}>
                      <Grid
                        container
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        style={{
                          marginTop: "5rem",
                        }}
                      >
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={6}
                          lg={6}
                          style={{
                            // minWidth: "25rem",
                            // minHight: "20rem",
                            //      margin: "1rem 0rem",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <div
                            class="zoom"
                            onClick={() => {
                              navigate("/dd");
                            }}
                          >
                            <Typography
                              variant="h5"
                              style={{
                                // color: "white",
                                margin: "1rem 0rem",
                                fontWeight: 500,
                                textAlign: "start",
                              }}
                            >
                              DD Free Dish
                            </Typography>
                            <p
                              variant="h7"
                              style={{
                                // color: "white",
                                margin: "1rem 0rem",
                                textAlign: "left",
                                marginTop: "0rem",
                                fontWeight: 300,
                              }}
                            >
                            Identity Design | Branding
                            </p>
                            <div
                              style={{ position: "relative", display: "flex" }}
                              class="zoom"
                            >
                              <img
                                style={{
                                  borderRadius: "20px",
                                  
                                  // maxWidth: "400px",
                                  // maxHeight: "400px",
                                  // minHeight: "400px",
                                  // minWidth: "400px",
                                }}
                                src={dd}
                                alt="img"
                              />
                              <img
                                src={circle}
                                alt="img"
                                style={{
                                  width: "8%",
                                  position: "absolute",
                                  bottom: "15px",
                                  right: "15px",
                                }}
                              />
                              <img
                                src={next}
                                alt="img"
                                style={{
                                  width: "3%",
                                  position: "absolute",
                                  bottom: "29px",
                                  right: "28px",
                                }}
                              />
                            </div>
                          </div>
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={6}
                          lg={6}
                          style={{
                            // minWidth: "25rem",
                            // minHight: "20rem",
                            //      margin: "1rem 0rem",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <div
                            class="zoom"
                            onClick={() => {
                              navigate("/loader");
                            }}
                          >
                            <Typography
                              variant="h5"
                              style={{
                                // color: "white",
                                margin: "1rem 0rem",
                                fontWeight: 500,
                                textAlign: "start",
                              }}
                            >
                              DD Free Dish Loader
                            </Typography>
                            <p
                              variant="h6"
                              style={{
                                // color: "white",
                                margin: "1rem 0rem",
                                textAlign: "left",
                                marginTop: "0rem",
                                fontWeight: 300,
                              }}
                            >
                          Motion Graphic
                            </p>
                            <div
                              style={{ position: "relative", display: "flex" }}
                            >
                              <img
                                style={{
                                  borderRadius: "20px",
                                  
                                  // maxWidth: "400px",
                                  // maxHeight: "400px",
                                  // minHeight: "330px",
                                  // minWidth: "330px",
                                }}
                                src={ddLoader}
                                alt="img"
                              />
                              <img
                                src={circle}
                                alt="img"
                                style={{
                                  width: "8%",
                                  position: "absolute",
                                  bottom: "15px",
                                  right: "15px",
                                }}
                              />
                              <img
                                src={next}
                                alt="img"
                                style={{
                                  width: "3%",
                                  position: "absolute",
                                  bottom: "29px",
                                  right: "28px",
                                }}
                              />
                            </div>
                          </div>
                        </Grid>
                      </Grid>

                      <Grid
                        container
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        style={{
                          marginTop: "5rem",
                        }}
                      >
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={6}
                          lg={6}
                          style={{
                            // minWidth: "25rem",
                            // minHight: "20rem",
                            //      margin: "1rem 0rem",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <div
                            class="zoom"
                            onClick={() => {
                              navigate("/palate");
                            }}
                          >
                            <Typography
                              variant="h5"
                              style={{
                                // color: "white",
                                margin: "1rem 0rem",
                                fontWeight: 500,
                                textAlign: "start",
                              }}
                            >
                              Palate
                            </Typography>
                            <p
                              variant="h6"
                              style={{
                                // color: "white",
                                margin: "1rem 0rem",
                                textAlign: "left",
                                marginTop: "0rem",
                                fontWeight: 300,
                              }}
                            >
                              Publication Design
                              <br />1 Week
                            </p>
                            <div
                              style={{ position: "relative", display: "flex" }}
                            >
                              <img
                                style={{
                                  borderRadius: "20px",
                                  
                                  // maxWidth: "400px",
                                  // maxHeight: "400px",
                                  // minHeight: "400px",
                                  // minWidth: "400px",
                                }}
                                src={Palate}
                                alt="img"
                              />
                              <img
                                src={circle}
                                alt="img"
                                style={{
                                  width: "8%",
                                  position: "absolute",
                                  bottom: "15px",
                                  right: "15px",
                                }}
                              />
                              <img
                                src={next}
                                alt="img"
                                style={{
                                  width: "3%",
                                  position: "absolute",
                                  bottom: "29px",
                                  right: "28px",
                                }}
                              />
                            </div>
                          </div>
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={6}
                          lg={6}
                          style={{
                            // minWidth: "25rem",
                            // minHight: "20rem",
                            //      margin: "1rem 0rem",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <div
                            class="zoom"
                            onClick={() => {
                              navigate("/graphic");
                            }}
                          >
                            <Typography
                              variant="h5"
                              style={{
                                // color: "white",
                                margin: "1rem 0rem",
                                fontWeight: 500,
                                textAlign: "start",
                              }}
                            >
                              Instructional Graphic
                            </Typography>
                            <p
                              variant="h6"
                              style={{
                                // color: "white",
                                margin: "1rem 0rem",
                                textAlign: "left",
                                marginTop: "0rem",
                                fontWeight: 300,
                              }}
                            >
                        Information Design
                            </p>
                            <div
                              style={{ position: "relative", display: "flex" }}
                            >
                              <img
                                style={{
                                  borderRadius: "20px",
                                  
                                  // maxWidth: "400px",
                                  // maxHeight: "400px",
                                  // minHeight: "330px",
                                  // minWidth: "330px",
                                }}
                                src={graphic}
                                alt="img"
                              />
                              <img
                                src={circle}
                                alt="img"
                                style={{
                                  width: "8%",
                                  position: "absolute",
                                  bottom: "15px",
                                  right: "15px",
                                }}
                              />
                              <img
                                src={next}
                                alt="img"
                                style={{
                                  width: "3%",
                                  position: "absolute",
                                  bottom: "29px",
                                  right: "28px",
                                }}
                              />
                            </div>
                          </div>
                        </Grid>
                      </Grid>

                      <Grid
                        container
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        style={{
                          marginTop: "5rem",
                        }}
                      >
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={6}
                          lg={6}
                          style={{
                            // minWidth: "25rem",
                            // minHight: "20rem",
                            //      margin: "1rem 0rem",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <div
                            class="zoom"
                            onClick={() => {
                              navigate("/book-cover");
                            }}
                          >
                            <Typography
                              variant="h5"
                              style={{
                                // color: "white",
                                margin: "1rem 0rem",
                                fontWeight: 500,
                                textAlign: "start",
                              }}
                            >
                              Book Cover Re-Design
                            </Typography>
                            <p
                              variant="h6"
                              style={{
                                // color: "white",
                                margin: "1rem 0rem",
                                textAlign: "left",
                                marginTop: "0rem",
                                fontWeight: 300,
                              }}
                            >
                              Publication Design
                              <br />1 Week
                            </p>
                            <div
                              style={{ position: "relative", display: "flex" }}
                            >
                              <img
                                style={{
                                  borderRadius: "20px",
                                  
                                  // maxWidth: "400px",
                                  // maxHeight: "400px",
                                  // minHeight: "400px",
                                  // minWidth: "400px",
                                }}
                                src={bookCover}
                                alt="img"
                              />
                              <img
                                src={circle}
                                alt="img"
                                style={{
                                  width: "8%",
                                  position: "absolute",
                                  bottom: "15px",
                                  right: "15px",
                                }}
                              />
                              <img
                                src={next}
                                alt="img"
                                style={{
                                  width: "3%",
                                  position: "absolute",
                                  bottom: "29px",
                                  right: "28px",
                                }}
                              />
                            </div>
                          </div>
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={6}
                          lg={6}
                          style={{
                            // minWidth: "25rem",
                            // minHight: "20rem",
                            //      margin: "1rem 0rem",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <div
                            class="zoom"
                            onClick={() => {
                              navigate("/puns");
                            }}
                          >
                            <Typography
                              variant="h5"
                              style={{
                                // color: "white",
                                margin: "1rem 0rem",
                                fontWeight: 500,
                                textAlign: "start",
                              }}
                            >
                              Puns
                            </Typography>
                            <p
                              variant="h7"
                              style={{
                                // color: "white",
                                margin: "1rem 0rem",
                                textAlign: "left",
                                marginTop: "0rem",
                                fontWeight: 300,
                              }}
                            >
                              Visual & Situational Puns
                              <br />1 Week
                            </p>
                            <div
                              style={{ position: "relative", display: "flex" }}
                              class="zoom"
                            >
                              <img
                                style={{
                                  borderRadius: "20px",
                                  
                                  // maxWidth: "400px",
                                  // maxHeight: "400px",
                                  // minHeight: "400px",
                                  // minWidth: "400px",
                                }}
                                src={puns}
                                alt="img"
                              />
                              <img
                                src={circle}
                                alt="img"
                                style={{
                                  width: "8%",
                                  position: "absolute",
                                  bottom: "15px",
                                  right: "15px",
                                }}
                              />
                              <img
                                src={next}
                                alt="img"
                                style={{
                                  width: "3%",
                                  position: "absolute",
                                  bottom: "29px",
                                  right: "28px",
                                }}
                              />
                            </div>
                          </div>
                        </Grid>
                      </Grid>

                      <Grid
                        container
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        style={{
                          marginTop: "5rem",
                        }}
                      >
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={6}
                          lg={6}
                          style={{
                            // minWidth: "25rem",
                            // minHight: "20rem",
                            //      margin: "1rem 0rem",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <div
                            class="zoom"
                            onClick={() => {
                              navigate("/rush");
                            }}
                          >
                            <Typography
                              variant="h5"
                              style={{
                                // color: "white",
                                margin: "1rem 0rem",
                                fontWeight: 500,
                                textAlign: "start",
                              }}
                            >
                              Rush Hour
                            </Typography>
                            <p
                              variant="h6"
                              style={{
                                // color: "white",
                                margin: "1rem 0rem",
                                textAlign: "left",
                                marginTop: "0rem",
                                fontWeight: 300,
                              }}
                            >
                              Stop Motion Animation
                              <br />1 Week
                            </p>
                            <div
                              style={{ position: "relative", display: "flex" }}
                            >
                              <img
                                style={{
                                  borderRadius: "20px",
                                  
                                  // maxWidth: "400px",
                                  // maxHeight: "400px",
                                  // minHeight: "330px",
                                  // minWidth: "330px",
                                }}
                                src={rush}
                                alt="img"
                              />
                              <img
                                src={circle}
                                alt="img"
                                style={{
                                  width: "8%",
                                  position: "absolute",
                                  bottom: "15px",
                                  right: "15px",
                                }}
                              />
                              <img
                                src={next}
                                alt="img"
                                style={{
                                  width: "3%",
                                  position: "absolute",
                                  bottom: "29px",
                                  right: "28px",
                                }}
                              />
                            </div>
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                  </TabPanel>
                  <TabPanel value="3">
                    <Grid style={{ marginBottom: "5rem" }}>
                      <Grid
                        container
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        style={{
                          marginTop: "5rem",
                        }}
                      >
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={6}
                          lg={6}
                          style={{
                            // minWidth: "25rem",
                            // minHight: "20rem",
                            //      margin: "1rem 0rem",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <div
                            class="zoom"
                            onClick={() => {
                              navigate("/lincout");
                            }}
                          >
                            <Typography
                              variant="h5"
                              style={{
                                // color: "white",
                                margin: "1rem 0rem",
                                fontWeight: 500,
                                textAlign: "start",
                              }}
                            >
                              Linocut
                            </Typography>
                            <p
                              variant="h7"
                              style={{
                                // color: "white",
                                margin: "1rem 0rem",
                                textAlign: "left",
                                marginTop: "0rem",
                                fontWeight: 300,
                              }}
                            >
                              Departmental Elective : What a Relief
                              <br />1 Week
                            </p>
                            <div
                              style={{ position: "relative", display: "flex" }}
                              class="zoom"
                            >
                              <img
                                style={{
                                  borderRadius: "20px",
                                  
                                  // maxWidth: "400px",
                                  // maxHeight: "400px",
                                  // minHeight: "400px",
                                  // minWidth: "400px",
                                }}
                                src={Linocut}
                                alt="img"
                              />
                              <img
                                src={circle}
                                alt="img"
                                style={{
                                  width: "8%",
                                  position: "absolute",
                                  bottom: "15px",
                                  right: "15px",
                                }}
                              />
                              <img
                                src={next}
                                alt="img"
                                style={{
                                  width: "3%",
                                  position: "absolute",
                                  bottom: "29px",
                                  right: "28px",
                                }}
                              />
                            </div>
                          </div>
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={6}
                          lg={6}
                          style={{
                            // minWidth: "25rem",
                            // minHight: "20rem",
                            //      margin: "1rem 0rem",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <div
                            class="zoom"
                            onClick={() => {
                              navigate("/sunshine");
                            }}
                          >
                            <Typography
                              variant="h5"
                              style={{
                                // color: "white",
                                margin: "1rem 0rem",
                                fontWeight: 500,
                                textAlign: "start",
                              }}
                            >
                              Give me some sunshine
                            </Typography>
                            <p
                              variant="h6"
                              style={{
                                // color: "white",
                                margin: "1rem 0rem",
                                textAlign: "left",
                                marginTop: "0rem",
                                fontWeight: 300,
                              }}
                            >
                              Screen Printing , Notebook Binding
                              <br />
                              Print Production Process | 1 Week
                            </p>
                            <div
                              style={{ position: "relative", display: "flex" }}
                            >
                              <img
                                style={{
                                  borderRadius: "20px",
                                  
                                  // maxWidth: "400px",
                                  // maxHeight: "400px",
                                  // minHeight: "330px",
                                  // minWidth: "330px",
                                }}
                                src={print}
                                alt="img"
                              />
                              <img
                                src={circle}
                                alt="img"
                                style={{
                                  width: "8%",
                                  position: "absolute",
                                  bottom: "15px",
                                  right: "15px",
                                }}
                              />
                              <img
                                src={next}
                                alt="img"
                                style={{
                                  width: "3%",
                                  position: "absolute",
                                  bottom: "29px",
                                  right: "28px",
                                }}
                              />
                            </div>
                          </div>
                        </Grid>
                      </Grid>

                      <Grid
                        container
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        style={{
                          marginTop: "5rem",
                        }}
                      >
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={6}
                          lg={6}
                          style={{
                            // minWidth: "25rem",
                            // minHight: "20rem",
                            //      margin: "1rem 0rem",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <div
                            class="zoom"
                            onClick={() => {
                              navigate("/zine");
                            }}
                          >
                            <Typography
                              variant="h5"
                              style={{
                                // color: "white",
                                margin: "1rem 0rem",
                                fontWeight: 500,
                                textAlign: "start",
                              }}
                            >
                              Zine
                            </Typography>
                            <p
                              variant="h6"
                              style={{
                                // color: "white",
                                margin: "1rem 0rem",
                                textAlign: "left",
                                marginTop: "0rem",
                                fontWeight: 300,
                              }}
                            >
                              Nylo Printing
                              <br />1 Week
                            </p>
                            <div
                              style={{ position: "relative", display: "flex" }}
                            >
                              <img
                                style={{
                                  borderRadius: "20px",
                                  
                                  // maxWidth: "400px",
                                  // maxHeight: "400px",
                                  // minHeight: "400px",
                                  // minWidth: "400px",
                                }}
                                src={zine}
                                alt="img"
                              />
                              <img
                                src={circle}
                                alt="img"
                                style={{
                                  width: "8%",
                                  position: "absolute",
                                  bottom: "15px",
                                  right: "15px",
                                }}
                              />
                              <img
                                src={next}
                                alt="img"
                                style={{
                                  width: "3%",
                                  position: "absolute",
                                  bottom: "29px",
                                  right: "28px",
                                }}
                              />
                            </div>
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                  </TabPanel>

                  <TabPanel value="4">
                    <Grid style={{ marginBottom: "5rem" }}>
                      <Grid
                        container
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        style={{
                          marginTop: "5rem",
                        }}
                      >
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={6}
                          lg={6}
                          style={{
                            // minWidth: "25rem",
                            // minHight: "20rem",
                            //      margin: "1rem 0rem",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <div
                            class="zoom"
                            onClick={() => {
                              navigate("/mood");
                            }}
                          >
                            <Typography
                              variant="h5"
                              style={{
                                // color: "white",
                                margin: "1rem 0rem",
                                fontWeight: 500,
                                textAlign: "start",
                              }}
                            >
                              Mood of Eid in the city
                            </Typography>
                            <p
                              variant="h6"
                              style={{
                                // color: "white",
                                margin: "1rem 0rem",
                                textAlign: "left",
                                marginTop: "0rem",
                                fontWeight: 300,
                              }}
                            >
                              Photography
                              <br />1 day
                            </p>
                            <div
                              style={{ position: "relative", display: "flex" }}
                            >
                              <img
                                style={{
                                  borderRadius: "20px",
                                  
                                  // maxWidth: "400px",
                                  // maxHeight: "400px",
                                  // minHeight: "330px",
                                  // minWidth: "330px",
                                }}
                                src={mood}
                                alt="img"
                              />
                              <img
                                src={circle}
                                alt="img"
                                style={{
                                  width: "8%",
                                  position: "absolute",
                                  bottom: "15px",
                                  right: "15px",
                                }}
                              />
                              <img
                                src={next}
                                alt="img"
                                style={{
                                  width: "3%",
                                  position: "absolute",
                                  bottom: "29px",
                                  right: "28px",
                                }}
                              />
                            </div>
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                  </TabPanel>
                </TabContext>
              </Box>
            </div>

            <div class="SecondSectionMobile">
              <Grid
                style={{
                  overflow: "scroll",
                  display: "flex",
                  width: "100%",
                  scrollbarWidth: "none",
                }}
              >
                {Tabs?.map((obj, index) => {
                  return (
                    <>
                      <div>
                        <Typography
                          style={{
                            display: "flex",
                            width: "max-content",
                            padding: "1rem",
                          }}
                          key={obj}
                          onClick={() => setSelecteVlue(obj)}
                        >
                          <span
                            style={{
                              color: selecteVlue === obj ? "yellow" : "white",
                            }}
                          >
                            {obj}
                          </span>
                        </Typography>
                      </div>
                    </>
                  );
                })}
              </Grid>

              {selecteVlue === "UI/UX Case Studies" && (
                <>
                  <Grid container sm={12} xs={12} md={12} lg={12}>
                    <Grid
                      sm={6}
                      xs={6}
                      md={6}
                      lg={6}
                      onClick={() => {
                        navigate("/graduation");
                      }}
                    >
                      <div
                        style={{
                          position: "relative",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          style={{
                            borderRadius: "8px",
                            marginTop: "3rem",
                            width: "70%",
                          }}
                          src={innovation}
                          alt="img"
                        />
                        <img
                          src={circle}
                          alt="img"
                          style={{
                            width: "5%",
                            position: "absolute",
                            bottom: "5px",
                            right: "33px",
                          }}
                        />
                        <img
                          src={next}
                          alt="img"
                          style={{
                            width: "2%",
                            position: "absolute",
                            bottom: "8px",
                            right: "36px",
                          }}
                        />
                      </div>
                      <Typography
                        style={{
                          color: "white",
                          margin: "0.5rem 1.8rem",
                          fontWeight: 500,
                          lineHeight: 1,
                          // marginBottom:"0.5rem",
                          textAlign: "start",
                        }}
                      >
                        <span style={{ fontSize: "0.8rem" }}>
                          Navigating Innovations
                        </span>
                      </Typography>
                      <p
                        variant="h7"
                        style={{
                          color: "white",
                          margin: "0rem 1.8rem",
                          textAlign: "left",
                          marginTop: "0rem",
                          fontSize: "0.5rem",
                          fontWeight: 300,
                        }}
                      >
                        Graduation Project | Cyntra Labs Pvt Ltd
                        <br />
                        Aug 23 - Jan 24
                      </p>
                    </Grid>
                    <Grid
                      sm={6}
                      xs={6}
                      md={6}
                      lg={6}
                      onClick={() => {
                        navigate("/danPatra");
                      }}
                    >
                      <div
                        style={{
                          position: "relative",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          style={{
                            borderRadius: "8px",
                            marginTop: "3rem",
                            width: "70%",
                          }}
                          src={porject1}
                          alt="img"
                        />
                        <img
                          src={circle}
                          alt="img"
                          style={{
                            width: "5%",
                            position: "absolute",
                            bottom: "5px",
                            right: "33px",
                          }}
                        />
                        <img
                          src={next}
                          alt="img"
                          style={{
                            width: "2%",
                            position: "absolute",
                            bottom: "8px",
                            right: "36px",
                          }}
                        />
                      </div>
                      <Typography
                        style={{
                          color: "white",
                          margin: "0.5rem 1.8rem",
                          fontWeight: 500,
                          // marginBottom:"0.5rem",
                          textAlign: "start",
                        }}
                      >
                        <span style={{ fontSize: "0.8rem" }}>DaanPatra</span>
                      </Typography>
                      <p
                        variant="h7"
                        style={{
                          color: "white",
                          margin: "0rem 1.8rem",
                          textAlign: "left",
                          marginTop: "0rem",
                          fontSize: "0.5rem",
                          fontWeight: 300,
                        }}
                      >
                        Context and Information Systems
                        <br />
                        System Design | UI/UX Design | 8 Weeks
                      </p>
                    </Grid>
                  </Grid>

                  <Grid container sm={12} xs={12} md={12} lg={12}>
                    <Grid
                      sm={6}
                      xs={6}
                      md={6}
                      lg={6}
                      onClick={() => {
                        navigate("/msme");
                      }}
                    >
                      <div
                        style={{
                          position: "relative",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          style={{
                            borderRadius: "8px",
                            marginTop: "3rem",
                            width: "70%",
                          }}
                          src={msme}
                          alt="img"
                        />
                        <img
                          src={circle}
                          alt="img"
                          style={{
                            width: "5%",
                            position: "absolute",
                            bottom: "5px",
                            right: "33px",
                          }}
                        />
                        <img
                          src={next}
                          alt="img"
                          style={{
                            width: "2%",
                            position: "absolute",
                            bottom: "8px",
                            right: "36px",
                          }}
                        />
                      </div>
                      <Typography
                        style={{
                          color: "white",
                          margin: "0.5rem 1.8rem",
                          fontWeight: 500,
                          // marginBottom:"0.5rem",
                          textAlign: "start",
                        }}
                      >
                        <span style={{ fontSize: "0.8rem" }}>MSME Sathi</span>
                      </Typography>
                      <p
                        variant="h7"
                        style={{
                          color: "white",
                          margin: "0rem 1.8rem",
                          textAlign: "left",
                          marginTop: "0rem",
                          fontSize: "0.5rem",
                          fontWeight: 300,
                        }}
                      >
                        Strategies for visual communication
                        <br />
                        Strategy Design | UX Design | 12 Weeks
                      </p>
                    </Grid>
                    <Grid
                      sm={6}
                      xs={6}
                      md={6}
                      lg={6}
                      onClick={() => {
                        navigate("/jenga");
                      }}
                    >
                      <div
                        style={{
                          position: "relative",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          style={{
                            borderRadius: "8px",
                            marginTop: "3rem",
                            width: "70%",
                          }}
                          src={jenga}
                          alt="img"
                        />
                        <img
                          src={circle}
                          alt="img"
                          style={{
                            width: "5%",
                            position: "absolute",
                            bottom: "5px",
                            right: "33px",
                          }}
                        />
                        <img
                          src={next}
                          alt="img"
                          style={{
                            width: "2%",
                            position: "absolute",
                            bottom: "8px",
                            right: "36px",
                          }}
                        />
                      </div>
                      <Typography
                        style={{
                          color: "white",
                          margin: "0.5rem 1.8rem",
                          fontWeight: 500,
                          lineHeight: 1,
                          // marginBottom:"0.5rem",
                          textAlign: "start",
                        }}
                      >
                        <span style={{ fontSize: "0.8rem" }}>
                          Digital Game Design : Jenga
                        </span>
                      </Typography>
                      <p
                        variant="h7"
                        style={{
                          color: "white",
                          margin: "0rem 1.8rem",
                          textAlign: "left",
                          marginTop: "0rem",
                          fontSize: "0.5rem",
                          fontWeight: 300,
                        }}
                      >
                        Design Challenge
                        <br />
                        Game Design | UI/UX Design | 2 Days
                      </p>
                    </Grid>
                  </Grid>

                  <Grid container sm={12} xs={12} md={12} lg={12}>
                    <Grid
                      sm={6}
                      xs={6}
                      md={6}
                      lg={6}
                      onClick={() => {
                        navigate("/kitchen");
                      }}
                    >
                      <div
                        style={{
                          position: "relative",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          style={{
                            borderRadius: "8px",
                            marginTop: "3rem",
                            width: "70%",
                          }}
                          src={kitchen}
                          alt="img"
                        />
                        <img
                          src={circle}
                          alt="img"
                          style={{
                            width: "5%",
                            position: "absolute",
                            bottom: "5px",
                            right: "33px",
                          }}
                        />
                        <img
                          src={next}
                          alt="img"
                          style={{
                            width: "2%",
                            position: "absolute",
                            bottom: "8px",
                            right: "36px",
                          }}
                        />
                      </div>
                      <Typography
                        style={{
                          color: "white",
                          margin: "0.5rem 1.8rem",
                          fontWeight: 500,
                          lineHeight: 1,
                          // marginBottom:"0.5rem",
                          textAlign: "start",
                        }}
                      >
                        <span style={{ fontSize: "0.8rem" }}>
                          System Study : Kitchen
                        </span>
                      </Typography>
                      <p
                        variant="h7"
                        style={{
                          color: "white",
                          margin: "0rem 1.8rem",
                          textAlign: "left",
                          marginTop: "0rem",
                          fontSize: "0.5rem",
                          fontWeight: 300,
                        }}
                      >
                        Research methodology
                        <br />
                        System Design | UI/UX Design | 2 Weeks
                      </p>
                    </Grid>
                  </Grid>
                </>
              )}

              {selecteVlue === "Visual Design" && (
                <>
                  <Grid container sm={12} xs={12} md={12} lg={12}>
                    <Grid
                      sm={6}
                      xs={6}
                      md={6}
                      lg={6}
                      onClick={() => {
                        navigate("/dd");
                      }}
                    >
                      <div
                        style={{
                          position: "relative",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          style={{
                            borderRadius: "8px",
                            marginTop: "3rem",
                            width: "70%",
                          }}
                          src={dd}
                          alt="img"
                        />
                        <img
                          src={circle}
                          alt="img"
                          style={{
                            width: "5%",
                            position: "absolute",
                            bottom: "5px",
                            right: "33px",
                          }}
                        />
                        <img
                          src={next}
                          alt="img"
                          style={{
                            width: "2%",
                            position: "absolute",
                            bottom: "8px",
                            right: "36px",
                          }}
                        />
                      </div>
                      <Typography
                        style={{
                          color: "white",
                          margin: "0.5rem 1.8rem",
                          fontWeight: 500,
                          // marginBottom:"0.5rem",
                          textAlign: "start",
                        }}
                      >
                        <span style={{ fontSize: "0.8rem" }}>DD Free Dish</span>
                      </Typography>
                      <p
                        variant="h7"
                        style={{
                          color: "white",
                          margin: "0rem 1.8rem",
                          textAlign: "left",
                          marginTop: "0rem",
                          fontSize: "0.5rem",
                          fontWeight: 300,
                        }}
                      >
                      Identity Design | Branding
               
                      </p>
                    </Grid>
                    <Grid
                      sm={6}
                      xs={6}
                      md={6}
                      lg={6}
                      onClick={() => {
                        navigate("/loader");
                      }}
                    >
                      <div
                        style={{
                          position: "relative",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          style={{
                            borderRadius: "8px",
                            marginTop: "3rem",
                            width: "70%",
                          }}
                          src={ddLoader}
                          alt="img"
                        />
                        <img
                          src={circle}
                          alt="img"
                          style={{
                            width: "5%",
                            position: "absolute",
                            bottom: "5px",
                            right: "33px",
                          }}
                        />
                        <img
                          src={next}
                          alt="img"
                          style={{
                            width: "2%",
                            position: "absolute",
                            bottom: "8px",
                            right: "36px",
                          }}
                        />
                      </div>
                      <Typography
                        style={{
                          color: "white",
                          margin: "0.5rem 1.8rem",
                          fontWeight: 500,
                          // marginBottom:"0.5rem",
                          textAlign: "start",
                        }}
                      >
                        <span style={{ fontSize: "0.8rem" }}>
                          DD Free Dish Loader
                        </span>
                      </Typography>
                      <p
                        variant="h7"
                        style={{
                          color: "white",
                          margin: "0rem 1.8rem",
                          textAlign: "left",
                          marginTop: "0rem",
                          fontSize: "0.5rem",
                          fontWeight: 300,
                        }}
                      >
                      Motion Graphic
                  
                      </p>
                    </Grid>
                  </Grid>
                  <Grid container sm={12} xs={12} md={12} lg={12}>
                    <Grid
                      sm={6}
                      xs={6}
                      md={6}
                      lg={6}
                      onClick={() => {
                        navigate("/palate");
                      }}
                    >
                      <div
                        style={{
                          position: "relative",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          style={{
                            borderRadius: "8px",
                            marginTop: "3rem",
                            width: "70%",
                          }}
                          src={Palate}
                          alt="img"
                        />
                        <img
                          src={circle}
                          alt="img"
                          style={{
                            width: "5%",
                            position: "absolute",
                            bottom: "5px",
                            right: "33px",
                          }}
                        />
                        <img
                          src={next}
                          alt="img"
                          style={{
                            width: "2%",
                            position: "absolute",
                            bottom: "8px",
                            right: "36px",
                          }}
                        />
                      </div>
                      <Typography
                        style={{
                          color: "white",
                          margin: "0.5rem 1.8rem",
                          fontWeight: 500,
                          lineHeight: 1,
                          // marginBottom:"0.5rem",
                          textAlign: "start",
                        }}
                      >
                        <span style={{ fontSize: "0.8rem" }}>Palate</span>
                      </Typography>
                      <p
                        variant="h7"
                        style={{
                          color: "white",
                          margin: "0rem 1.8rem",
                          textAlign: "left",
                          marginTop: "0rem",
                          fontSize: "0.5rem",
                          fontWeight: 300,
                        }}
                      >
                        Publication Design
                        <br />1 Week
                      </p>
                    </Grid>
                    <Grid
                      sm={6}
                      xs={6}
                      md={6}
                      lg={6}
                      onClick={() => {
                        navigate("/graphic");
                      }}
                    >
                      <div
                        style={{
                          position: "relative",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          style={{
                            borderRadius: "8px",
                            marginTop: "3rem",
                            width: "70%",
                          }}
                          src={graphic}
                          alt="img"
                        />
                        <img
                          src={circle}
                          alt="img"
                          style={{
                            width: "5%",
                            position: "absolute",
                            bottom: "5px",
                            right: "33px",
                          }}
                        />
                        <img
                          src={next}
                          alt="img"
                          style={{
                            width: "2%",
                            position: "absolute",
                            bottom: "8px",
                            right: "36px",
                          }}
                        />
                      </div>
                      <Typography
                        style={{
                          color: "white",
                          margin: "0.5rem 1.8rem",
                          fontWeight: 500,
                          lineHeight: 1,
                          // marginBottom:"0.5rem",
                          textAlign: "start",
                        }}
                      >
                        <span style={{ fontSize: "0.8rem" }}>
                          Instructional Graphic
                        </span>
                      </Typography>
                      <p
                        variant="h7"
                        style={{
                          color: "white",
                          margin: "0rem 1.8rem",
                          textAlign: "left",
                          marginTop: "0rem",
                          fontSize: "0.5rem",
                          fontWeight: 300,
                        }}
                      >
                   Information Design
                 
                      </p>
                    </Grid>
                  </Grid>
                  <Grid container sm={12} xs={12} md={12} lg={12}>
                    <Grid
                      sm={6}
                      xs={6}
                      md={6}
                      lg={6}
                      onClick={() => {
                        navigate("/book-cover");
                      }}
                    >
                      <div
                        style={{
                          position: "relative",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          style={{
                            borderRadius: "8px",
                            marginTop: "3rem",
                            width: "70%",
                          }}
                          src={bookCover}
                          alt="img"
                        />
                        <img
                          src={circle}
                          alt="img"
                          style={{
                            width: "5%",
                            position: "absolute",
                            bottom: "5px",
                            right: "33px",
                          }}
                        />
                        <img
                          src={next}
                          alt="img"
                          style={{
                            width: "2%",
                            position: "absolute",
                            bottom: "8px",
                            right: "36px",
                          }}
                        />
                      </div>
                      <Typography
                        style={{
                          color: "white",
                          margin: "0.5rem 1.8rem",
                          fontWeight: 500,
                          lineHeight: 1,
                          // marginBottom:"0.5rem",
                          textAlign: "start",
                        }}
                      >
                        <span style={{ fontSize: "0.8rem" }}>
                          Book Cover Re-Design
                        </span>
                      </Typography>
                      <p
                        variant="h7"
                        style={{
                          color: "white",
                          margin: "0rem 1.8rem",
                          textAlign: "left",
                          marginTop: "0rem",
                          fontSize: "0.5rem",
                          fontWeight: 300,
                        }}
                      >
                        Publication Design
                        <br />1 Week
                      </p>
                    </Grid>
                    <Grid
                      sm={6}
                      xs={6}
                      md={6}
                      lg={6}
                      onClick={() => {
                        navigate("/puns");
                      }}
                    >
                      <div
                        style={{
                          position: "relative",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          style={{
                            borderRadius: "8px",
                            marginTop: "3rem",
                            width: "70%",
                          }}
                          src={puns}
                          alt="img"
                        />
                        <img
                          src={circle}
                          alt="img"
                          style={{
                            width: "5%",
                            position: "absolute",
                            bottom: "5px",
                            right: "33px",
                          }}
                        />
                        <img
                          src={next}
                          alt="img"
                          style={{
                            width: "2%",
                            position: "absolute",
                            bottom: "8px",
                            right: "36px",
                          }}
                        />
                      </div>
                      <Typography
                        style={{
                          color: "white",
                          margin: "0.5rem 1.8rem",
                          fontWeight: 500,
                          lineHeight: 1,
                          // marginBottom:"0.5rem",
                          textAlign: "start",
                        }}
                      >
                        <span style={{ fontSize: "0.8rem" }}>Puns</span>
                      </Typography>
                      <p
                        variant="h7"
                        style={{
                          color: "white",
                          margin: "0rem 1.8rem",
                          textAlign: "left",
                          marginTop: "0rem",
                          fontSize: "0.5rem",
                          fontWeight: 300,
                        }}
                      >
                        Visual & Situational Puns
                        <br />1 Week
                      </p>
                    </Grid>
                  </Grid>
                  <Grid container sm={12} xs={12} md={12} lg={12}>
                    <Grid
                      sm={6}
                      xs={6}
                      md={6}
                      lg={6}
                      onClick={() => {
                        navigate("/rush");
                      }}
                    >
                      <div
                        style={{
                          position: "relative",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          style={{
                            borderRadius: "8px",
                            marginTop: "3rem",
                            width: "70%",
                          }}
                          src={rush}
                          alt="img"
                        />
                        <img
                          src={circle}
                          alt="img"
                          style={{
                            width: "5%",
                            position: "absolute",
                            bottom: "5px",
                            right: "33px",
                          }}
                        />
                        <img
                          src={next}
                          alt="img"
                          style={{
                            width: "2%",
                            position: "absolute",
                            bottom: "8px",
                            right: "36px",
                          }}
                        />
                      </div>
                      <Typography
                        style={{
                          color: "white",
                          margin: "0.5rem 1.8rem",
                          fontWeight: 500,
                          lineHeight: 1,
                          // marginBottom:"0.5rem",
                          textAlign: "start",
                        }}
                      >
                        <span style={{ fontSize: "0.8rem" }}>Rush Hour</span>
                      </Typography>
                      <p
                        variant="h7"
                        style={{
                          color: "white",
                          margin: "0rem 1.8rem",
                          textAlign: "left",
                          marginTop: "0rem",
                          fontSize: "0.5rem",
                          fontWeight: 300,
                        }}
                      >
                        Stop Motion Animation
                        <br />1 Week
                      </p>
                    </Grid>
                  </Grid>
                </>
              )}

              {selecteVlue === "Print Making" && (
                <>
                  <Grid container sm={12} xs={12} md={12} lg={12}>
                    <Grid
                      sm={6}
                      xs={6}
                      md={6}
                      lg={6}
                      onClick={() => {
                        navigate("/lincout");
                      }}
                    >
                      <div
                        style={{
                          position: "relative",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          style={{
                            borderRadius: "8px",
                            marginTop: "3rem",
                            width: "70%",
                          }}
                          src={Linocut}
                          alt="img"
                        />
                        <img
                          src={circle}
                          alt="img"
                          style={{
                            width: "5%",
                            position: "absolute",
                            bottom: "5px",
                            right: "33px",
                          }}
                        />
                        <img
                          src={next}
                          alt="img"
                          style={{
                            width: "2%",
                            position: "absolute",
                            bottom: "8px",
                            right: "36px",
                          }}
                        />
                      </div>
                      <Typography
                        style={{
                          color: "white",
                          margin: "0.5rem 1.8rem",
                          fontWeight: 500,
                          // marginBottom:"0.5rem",
                          textAlign: "start",
                        }}
                      >
                        <span style={{ fontSize: "0.8rem" }}>Linocut</span>
                      </Typography>
                      <p
                        variant="h7"
                        style={{
                          color: "white",
                          margin: "0rem 1.8rem",
                          textAlign: "left",
                          marginTop: "0rem",
                          fontSize: "0.5rem",
                          fontWeight: 300,
                        }}
                      >
                        Departmental Elective : What a Relief
                        <br />1 Week
                      </p>
                    </Grid>
                    <Grid
                      sm={6}
                      xs={6}
                      md={6}
                      lg={6}
                      onClick={() => {
                        navigate("/sunshine");
                      }}
                    >
                      <div
                        style={{
                          position: "relative",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          style={{
                            borderRadius: "8px",
                            marginTop: "3rem",
                            width: "70%",
                          }}
                          src={print}
                          alt="img"
                        />
                        <img
                          src={circle}
                          alt="img"
                          style={{
                            width: "5%",
                            position: "absolute",
                            bottom: "5px",
                            right: "33px",
                          }}
                        />
                        <img
                          src={next}
                          alt="img"
                          style={{
                            width: "2%",
                            position: "absolute",
                            bottom: "8px",
                            right: "36px",
                          }}
                        />
                      </div>
                      <Typography
                        style={{
                          color: "white",
                          margin: "0.5rem 1.8rem",
                          fontWeight: 500,
                          lineHeight: 1,
                          // marginBottom:"0.5rem",
                          textAlign: "start",
                        }}
                      >
                        <span style={{ fontSize: "0.8rem" }}>
                          Give me some sunshine
                        </span>
                      </Typography>
                      <p
                        variant="h7"
                        style={{
                          color: "white",
                          margin: "0rem 1.8rem",
                          textAlign: "left",
                          marginTop: "0rem",
                          fontSize: "0.5rem",
                          fontWeight: 300,
                        }}
                      >
                        Screen Printing , Notebook Binding
                        <br />
                        Print Production Process | 1 Week
                      </p>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    sm={12}
                    xs={12}
                    md={12}
                    lg={12}
                    onClick={() => {
                      navigate("/zine");
                    }}
                  >
                    <Grid sm={6} xs={6} md={6} lg={6}>
                      <div
                        style={{
                          position: "relative",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          style={{
                            borderRadius: "8px",
                            marginTop: "3rem",
                            width: "70%",
                          }}
                          src={zine}
                          alt="img"
                        />
                        <img
                          src={circle}
                          alt="img"
                          style={{
                            width: "5%",
                            position: "absolute",
                            bottom: "5px",
                            right: "33px",
                          }}
                        />
                        <img
                          src={next}
                          alt="img"
                          style={{
                            width: "2%",
                            position: "absolute",
                            bottom: "8px",
                            right: "36px",
                          }}
                        />
                      </div>
                      <Typography
                        style={{
                          color: "white",
                          margin: "0.5rem 1.8rem",
                          fontWeight: 500,
                          lineHeight: 1,
                          // marginBottom:"0.5rem",
                          textAlign: "start",
                        }}
                      >
                        <span style={{ fontSize: "0.8rem" }}>Zine</span>
                      </Typography>
                      <p
                        variant="h7"
                        style={{
                          color: "white",
                          margin: "0rem 1.8rem",
                          textAlign: "left",
                          marginTop: "0rem",
                          fontSize: "0.5rem",
                          fontWeight: 300,
                        }}
                      >
                        Nylo Printing
                        <br />1 Week
                      </p>
                    </Grid>
                  </Grid>{" "}
                </>
              )}

              {selecteVlue === "Photography" && (
                <>
                  <Grid container sm={12} xs={12} md={12} lg={12}>
                    <Grid
                      sm={6}
                      xs={6}
                      md={6}
                      lg={6}
                      onClick={() => {
                        navigate("/mood");
                      }}
                    >
                      <div
                        style={{
                          position: "relative",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          style={{
                            borderRadius: "8px",
                            marginTop: "3rem",
                            width: "70%",
                          }}
                          src={mood}
                          alt="img"
                        />
                        <img
                          src={circle}
                          alt="img"
                          style={{
                            width: "5%",
                            position: "absolute",
                            bottom: "5px",
                            right: "33px",
                          }}
                        />
                        <img
                          src={next}
                          alt="img"
                          style={{
                            width: "2%",
                            position: "absolute",
                            bottom: "8px",
                            right: "36px",
                          }}
                        />
                      </div>
                      <Typography
                        style={{
                          color: "white",
                          margin: "0.5rem 1.8rem",
                          fontWeight: 500,
                          lineHeight: 1,
                          // marginBottom:"0.5rem",
                          textAlign: "start",
                        }}
                      >
                        <span style={{ fontSize: "0.8rem" }}>
                          Mood of Eid in the city
                        </span>
                      </Typography>
                      <p
                        variant="h7"
                        style={{
                          color: "white",
                          margin: "0rem 1.8rem",
                          textAlign: "left",
                          marginTop: "0rem",
                          fontSize: "0.5rem",
                          fontWeight: 300,
                        }}
                      >
                        Photography
                        <br />1 day
                      </p>
                    </Grid>
                  </Grid>
                </>
              )}
            </div>
          </Grid>
        </div>
      </div>
    </>
  );
}
