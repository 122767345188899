import React from "react";
import "../App.css";

import project from "../assets/pdf/graduation/Graduation.jpg";

import Header from "../Common/Header";
export default function Project() {
  return (
    <div>
    <Header page={"work"} />

    <div style={{ paddingTop: "3rem" }}>
      <img
        alt="logo"
        src={project}
        style={{ width: "100%", display: "block" }}
      />
    </div>
  </div>
  )
}
