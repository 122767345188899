import React from "react";
import "../App.css";

import img1 from "../assets/pdf/rush/1-1.png";
import img3 from "../assets/pdf/rush/3-1.png";
import img2 from "../assets/pdf/rush/2-1.png";
import img5 from "../assets/pdf/rush/5-1.png";
import video from "../assets/pdf/rush/Rush.mp4";

import img6 from "../assets/pdf/rush/6-1.png";






import Header from "../Common/Header";

export default function Rush() {
  return (
    <div>
    <Header page={"work"} />

    <div style={{ paddingTop: "3rem" }}>
      <img
        alt="logo"
        src={img1}
        style={{ width: "100%", display: "block" }}
      />
    <img
        alt="logo"
        src={img2}
        style={{ width: "100%", display: "block" }}
      />

      <img
        alt="logo"
        src={img3}
        style={{ width: "100%", display: "block" }}
      />
      
          <video width={"100%"} height={"100%"} autoplay="autoplay" controls>
          <source src={video} type="video/mp4"></source>
        </video>

      <img
        alt="logo"
        src={img5}
        style={{ width: "100%", display: "block" }}
      />
      <img
        alt="logo"
        src={img6}
        style={{ width: "100%", display: "block" }}
      /> 
    </div>
  </div>
  )
}
