import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./slider.css"; 
import { Typography, Grid } from "@mui/material";

const ImageSlider = ({ images }) => {
  const [centerIndex, setCenterIndex] = useState(0);
  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: "60px",
    autoplay: false,
    autoplaySpeed: 3000,
    focusOnSelect: true,
    beforeChange: (current, next) => {
      setCenterIndex(next);
    },
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          centerPadding: "25%",
          topPadding: "10px",
        },
      },
    ],
  };

  return (
    <>
      <Slider {...settings}>
        {images.map((image, index) => (
          <div key={index} className="slider-image-container">
            <img src={image} alt={`Slide ${index}`} className="slider-image" />
          </div>
        ))}
      </Slider>

      {centerIndex === 0 && (
        <Grid container style={{ padding: "1.5rem" }} class="fadeInUp-animation">
          <Typography
            variant="h4"
            style={{ color: "white", textAlign: "left" }}
          >
            About
          </Typography>
          <Typography
            style={{
              color: "white",
              textAlign: "left",
              padding: "1rem 0rem",
            }}
          >
            Hey there! I'm a UI/UX designer with a passion for crafting seamless
            digital experiences. From crafting intuitive digital experiences to
            capturing the essence of my adventures, I blend creativity with
            functionality in everything I do.
          </Typography>
          <Typography
            style={{
              color: "white",
              textAlign: "left",
              padding: "1rem 0rem",
            }}
          >
            With an eye for detail both in design and in life, I bring
            creativity and curiosity to every project and adventure. Explore my
            portfolio to witness how I transform ideas into engaging experiences
          </Typography>
        </Grid>
      )}

      {centerIndex === 1 && (
        <Grid container style={{ padding: "1.5rem" }} class="fadeInUp-animation">
          <Typography
            variant="h4"
            style={{ color: "white", textAlign: "left", padding: "1rem 0rem" }}
          >
            Background
          </Typography>
          <Typography style={{ color: "white", textAlign: "left" }}>
            Born in the bustling city of Kanpur and raised in different parts of
            the country, my journey as a designer has been deeply influenced by
            a multicultural upbringings.
          </Typography>
          <Typography
            style={{
              color: "white",
              textAlign: "left",
              padding: "1rem 0rem",
            }}
          >
            During my undergraduate studies, I delved into the world of knitwear
            design. It was during this time that I first witnessed the
            transformative power of design. However my will to expand horizons
            and pushing boundaries, led me to pursue master’s degree in graphic
            design.
          </Typography>
          <Typography
            style={{
              color: "white",
              textAlign: "left",
              padding: "1rem 0rem",
            }}
          >
            My unique background, in different part’s of the country has
            installed in me a deep appreciation for diversity. It is here that I
            find myself today, immersed in a world of creativity and innovation,
            bridging the gap between aesthetics and functionality.
          </Typography>
        </Grid>
      )}

      {centerIndex === 2 && (
        <Grid container style={{ padding: "1.5rem" }} class="fadeInUp-animation">
          <Typography
            variant="h4"
            style={{ color: "white", textAlign: "left", padding: "1rem 0rem" }}
          >
            Experience
          </Typography>
          <Typography style={{ color: "white", textAlign: "left" }}>
            It all started with needles and yarns as I dipped my toes into the
            world of knitwear design. I embarked on my first adventure into the
            world of design, crafting knitwear wonders for “Madame”. But my
            hunger for innovation didn’t stop there and I joined “Aleta, USA” as
            a fashion designer where I graced the glamorous frames of big name
            celebrities.
          </Typography>
          <Typography
            style={{
              color: "white",
              textAlign: "left",
              padding: "1rem 0rem",
            }}
          >
            On the side, I delved into the digital domain freelancing as a
            graphic designer, be it graffiti graphics or captivating branding.
          </Typography>
          <Typography
            style={{
              color: "white",
              textAlign: "left",
              padding: "1rem 0rem",
            }}
          >
            Then NID, Ahmedabad beckoned - here I wore yet another hat as a
            cultural coordinator, immersing myself in a whirl mind of artistic
            expression and community
          </Typography>
          <Typography
            style={{
              color: "white",
              textAlign: "left",
              padding: "1rem 0rem",
            }}
          >
            Diving deeper my first UI/UX internship opened doors to world of
            segmented displays and digital clusters for EV vehicles. In my
            graduation project I crafted intuitive self ordering kiosks that are
            now bringing convenience to the masses.
          </Typography>
          <Typography
            style={{
              color: "white",
              textAlign: "left",
              padding: "1rem 0rem",
            }}
          >
            From needles to pixels, from runways to digital highways, my career
            has been a kaleidoscope of experiences. And guess what? I’m just
            getting started.
          </Typography>
        </Grid>
      )}

      {centerIndex === 3 && (
        <Grid container style={{ padding: "1.5rem" }} class="fadeInUp-animation">
          <Typography
            variant="h4"
            style={{ color: "white", textAlign: "left", padding: "1rem 0rem" }}
          >
            Interest
          </Typography>
          <Typography style={{ color: "white", textAlign: "left" }}>
            The wild wanderer with a passion for pixels, pencils and picking up
            pieces each one a tangible reminder of the places I’ve been and the
            experiences I’ve had. From kitschy trinklets to vintage postcards my
            treasure trove knows no bounds.
          </Typography>
          <Typography
            style={{
              color: "white",
              textAlign: "left",
              padding: "1rem 0rem",
            }}
          >
            Flip through the pages of my journal, and you will find a menagerie
            of characters or urban landscape brought to life.
          </Typography>
          <Typography
            style={{
              color: "white",
              textAlign: "left",
              padding: "1rem 0rem",
            }}
          >
            It’s not just about what I put on paper; it’s also about what I see
            through the lens of my camera be it urban jungles, tranquil
            landscapes or candid moments.
          </Typography>
          <Typography
            style={{
              color: "white",
              textAlign: "left",
              padding: "1rem 0rem",
            }}
          >
            Ah, Music! - the soundtrack to my adventures! whether it’s the
            soulful strains of Hindustani melodies or the pulsating beats of the
            streets, I’m always grooving to the rhythm of life.
          </Typography>
          <Typography
            style={{
              color: "white",
              textAlign: "left",
              padding: "1rem 0rem",
            }}
          >
            So if you ever catch a glimpse of a quirky character with a
            sketchbook in on hand, a camera in the other and a smile as wide as
            the horizon, that’s probably me - the designer on a perpetual
            adventure.
          </Typography>
        </Grid>
      )}
    </>
  );
};

export default ImageSlider;
