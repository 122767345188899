import React from "react";
import "../App.css";

import img1 from "../assets/pdf/kitchen/1.jpg";
import img2 from "../assets/pdf/kitchen/2.jpg";
import img3 from "../assets/pdf/kitchen/3.jpg";
import img4 from "../assets/pdf/kitchen/4.jpg";
import img5 from "../assets/pdf/kitchen/5.jpg";
import img6 from "../assets/pdf/kitchen/6.jpg";
import img7 from "../assets/pdf/kitchen/7.jpg";

import img8 from "../assets/pdf/kitchen/8.jpg";
import img9 from "../assets/pdf/kitchen/9.jpg";
import img10 from "../assets/pdf/kitchen/10.jpg";
import img11 from "../assets/pdf/kitchen/11.jpg";

import img12 from "../assets/pdf/kitchen/12.jpg";
import img13 from "../assets/pdf/kitchen/13.jpg";
import img14 from "../assets/pdf/kitchen/14.jpg";
import img15 from "../assets/pdf/kitchen/15.jpg";
import img16 from "../assets/pdf/kitchen/16.jpg";
import img17 from "../assets/pdf/kitchen/17.jpg";
import img18 from "../assets/pdf/kitchen/18.jpg";

import img19 from "../assets/pdf/kitchen/19.jpg";
import img20 from "../assets/pdf/kitchen/20.jpg";


import Header from "../Common/Header";

export default function Kitchen() {
  return (
    <div>
    <Header page={"work"} />

    <div style={{ paddingTop: "3rem" }}>
      <img
        alt="logo"
        src={img1}
        style={{ width: "100%", display: "block" }}
      />
      <img
        alt="logo"
        src={img2}
        style={{ width: "100%", display: "block" }}
      />

      <img
        alt="logo"
        src={img3}
        style={{ width: "100%", display: "block" }}
      />
      <img
        alt="logo"
        src={img4}
        style={{ width: "100%", display: "block" }}
      />
      <img
        alt="logo"
        src={img5}
        style={{ width: "100%", display: "block" }}
      />
      <img
        alt="logo"
        src={img6}
        style={{ width: "100%", display: "block" }}
      />

      <img
        alt="logo"
        src={img7}
        style={{ width: "100%", display: "block" }}
      />
      <img
        alt="logo"
        src={img8}
        style={{ width: "100%", display: "block" }}
      />
      <img
        alt="logo"
        src={img9}
        style={{ width: "100%", display: "block" }}
      />
      <img
        alt="logo"
        src={img10}
        style={{ width: "100%", display: "block" }}
      />
      <img
        alt="logo"
        src={img11}
        style={{ width: "100%", display: "block" }}
      />
       <img
        alt="logo"
        src={img12}
        style={{ width: "100%", display: "block" }}
      />
       <img
        alt="logo"
        src={img13}
        style={{ width: "100%", display: "block" }}
      />
       <img
        alt="logo"
        src={img14}
        style={{ width: "100%", display: "block" }}
      />
       <img
        alt="logo"
        src={img15}
        style={{ width: "100%", display: "block" }}
      />
       <img
        alt="logo"
        src={img16}
        style={{ width: "100%", display: "block" }}
      />
       <img
        alt="logo"
        src={img17}
        style={{ width: "100%", display: "block" }}
      />
       <img
        alt="logo"
        src={img18}
        style={{ width: "100%", display: "block" }}
      />
       <img
        alt="logo"
        src={img19}
        style={{ width: "100%", display: "block" }}
      />
       <img
        alt="logo"
        src={img20}
        style={{ width: "100%", display: "block" }}
      />
    </div>
  </div>
  )
}
