import React from "react";
import "../App.css";

import img1 from "../assets/pdf/mood/1-1.png";
import img2 from "../assets/pdf/mood/2-1.png";
import img3 from "../assets/pdf/mood/3-1.png";
import img4 from "../assets/pdf/mood/4-1.png";
import img5 from "../assets/pdf/mood/5-1.png";

import img6 from "../assets/pdf/mood/6-1.png";
import img7 from "../assets/pdf/mood/7-1.png";

// import img8 from "../assets/pdf/mood/8-1.png";
import img9 from "../assets/pdf/mood/9-1.png";
import img10 from "../assets/pdf/mood/10-1.png";
import img11 from "../assets/pdf/mood/11-1.png";
import img12 from "../assets/pdf/mood/12-1.png";


import Header from "../Common/Header";

export default function Mood() {
  return (
    <div>
    <Header page={"work"} />

    <div style={{ paddingTop: "3rem" }}>
      <img
        alt="logo"
        src={img1}
        style={{ width: "100%", display: "block" }}
      />
      <img
        alt="logo"
        src={img2}
        style={{ width: "100%", display: "block" }}
      />

      <img
        alt="logo"
        src={img3}
        style={{ width: "100%", display: "block" }}
      />
      <img
        alt="logo"
        src={img4}
        style={{ width: "100%", display: "block" }}
      />
      <img
        alt="logo"
        src={img5}
        style={{ width: "100%", display: "block" }}
      />
    

      <img
        alt="logo"
        src={img7}
        style={{ width: "100%", display: "block" }}
      />
      {/* <img
        alt="logo"
        src={img8}
        style={{ width: "100%", display: "block" }}
      /> */}
      <img
        alt="logo"
        src={img9}
        style={{ width: "100%", display: "block" }}
      />
          <img
        alt="logo"
        src={img10}
        style={{ width: "100%", display: "block" }}
      />
        <img
        alt="logo"
        src={img6}
        style={{ width: "100%", display: "block" }}
      />
  
      <img
        alt="logo"
        src={img11}
        style={{ width: "100%", display: "block" }}
      />
      <img
        alt="logo"
        src={img12}
        style={{ width: "100%", display: "block" }}
      />
  
     
    </div>
  </div>
  )
}
